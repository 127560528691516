<template>
  <li
    class="modal-view-members__item"
    @click="$router.push({ name: 'UserInfo', params: { user_id: item.id } })"
  >
    <div class="modal-view-members__wrap">
      <div
        class="modal-view-members__leader"
        v-if="item.id === currentCardData.responsible_user_id"
      ></div>
      <img
        class="modal-view-members__photo"
        :src="returnSrcFile(item.avatar)"
        width="36"
        height="36"
        alt=" "
      />
    </div>
    <div class="modal-view-members__description">
      <p class="modal-view-members__full-name">{{ item.full_name }}</p>
      <p class="modal-view-members__position">{{ item.roles[0] && item.roles[0].name }}</p>
    </div>
    <button
      class="modal-view-members__delete"
      type="button"
      v-if="deleteResponsibleAndAssistant(item)"
      @click.stop="deleteGroupsMemberUser(getMemberId)"
    ></button>
  </li>
</template>

<script>
import { domainStorage } from "@/globalVariables";

export default {
  name: "ViewMembersCard",
  mixins: [],
  props: ["item", "isAdmin", "currentCardData"],
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    returnSrcFile(src) {
      return domainStorage + src;
    },
    deleteGroupsMemberUser(item) {
      this.$emit("askDeleteGroupsMemberUser", item);
    },
    deleteResponsibleAndAssistant(item) {
      if (this.isAdmin) {
        if (this.currentCardData.users) {
          if (
            item.id !== this.currentCardData.responsible_user_id &&
            this.currentCardData.assistant_user_id === null
          ) {
            return true;
          } else if (
            this.currentCardData.responsible_user_id === null &&
            item.id !== this.currentCardData.assistant_user_id
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  computed: {
    getMemberId() {
      return this.item.org_structure_items.find((user) => user.id === this.currentCardData.id)
        .member_id;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";
.modal-view-members {
  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover .modal-view-members__full-name,
    &:hover .modal-view-members__position {
      color: $color-charts-purple-1;
    }

    &:hover .modal-view-members__delete {
      display: block;
    }
    @media screen and (hover: none) {
      .modal-view-members__delete {
        display: block;
      }
    }
  }

  &__wrap {
    position: relative;
    width: 40px;
    height: 34px;
    margin-right: 4px;
  }

  &__leader {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $color-charts-purple-1;
  }

  &__photo {
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__full-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: $color-text-accent;
    max-width: 240px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__position {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: $color-text-primary-3;
    max-width: 240px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__delete {
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    display: none;
    background-color: transparent;
    border: none;
    margin-left: auto;
    align-self: center;
    outline: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 9px;
      height: 2px;
      top: 8px;
      left: 8px;
      background-color: $color-text-primary-1;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
    &:hover .modal-view-members__delete::before,
    &:hover .modal-view-members__delete::after {
      background-color: $color-button-hover;
    }
  }
  .modal-view-members__delete:hover::before,
  .modal-view-members__delete:hover::after {
    background-color: $color-button-hover;
  }

  .modal-view-members__delete:active::before,
  .modal-view-members__delete:active::after {
    background-color: $color-button-click;
  }
}
</style>
